/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { Prices } from "gatsby-theme-prices";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import type {
  PageQuery,
  PricesWidgetQuery,
} from "../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetPrices" }>;
  data: PageQuery["theater"];
}

const PricesWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
  data: theaterData,
}) => {
  const data = useStaticQuery<PricesWidgetQuery>(graphql`
    query PricesWidget {
      theater {
        practicalInfo {
          pricing {
            id
            comment
            currency
            name
            price
          }
        }
      }
    }
  `);

  const selectedTheaterData = theaterData || data.theater;

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Prices theater={selectedTheaterData} />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetPrices on WidgetPrices {
    id
    __typename
  }
`;

export default memo(PricesWidget);
