/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo } from "react";
import { FormattedNumber } from "shared/components/FormattedNumber";
import { getMostVisible } from "shared/helpers/colors";
import { Property } from "shared/types/css";
import { Currency } from "shared/types/currency";
import { Maybe } from "shared/utils/ts";
import { jsx } from "theme-ui";

interface Props {
  readonly theater?: Maybe<{
    readonly practicalInfo?: Maybe<{
      readonly pricing?: Maybe<
        ReadonlyArray<
          Maybe<{
            readonly id: string;
            readonly price?: Maybe<number>;
            readonly name?: Maybe<string>;
            readonly comment?: Maybe<string>;
            readonly currency?: Maybe<Currency>;
          }>
        >
      >;
    }>;
  }>;
}

const Prices: React.FC<Props> = ({ theater }) => {
  if (!theater) {
    return null;
  }

  return (
    <div>
      {theater?.practicalInfo?.pricing?.map((pricing, index) => {
        return (
          <div
            key={pricing?.id || index}
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridTemplateRows: "auto",
              gridTemplateAreas: responsive({
                xs: '"name name name name" "comment comment comment comment" "price price price price"',
                sm: '"name name name name" "comment comment comment price"',
                md: '"name comment comment price"',
              }),
              borderBottomColor: (theme) =>
                getMostVisible(theme.rawColors?.background, [
                  theme.rawColors?.primary,
                ]).toHexString(),
              borderBottomStyle: "solid",
              borderBottomWidth: "small",
              py: 3,
              ":first-of-type": {
                paddingTop: 0,
              },
              ":last-of-type": {
                borderBottom: "none",
                paddingBottom: 0,
              },
            }}
          >
            <div sx={{ gridArea: "name", fontWeight: "bold", paddingRight: 2 }}>
              {pricing?.name || "—"}
            </div>
            <div sx={{ gridArea: "comment" }}>{pricing?.comment || "—"}</div>
            <div
              sx={{
                gridArea: "price",
                textAlign: responsive<Property.TextAlign>({
                  xs: "left",
                  sm: "right",
                }),
              }}
            >
              {pricing?.price && pricing?.currency ? (
                <FormattedNumber
                  value={pricing.price}
                  style="currency"
                  currency={pricing.currency}
                />
              ) : (
                "—"
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(Prices);
